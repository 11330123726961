import { computed, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

import { useDealsBase } from "./deals";
import {
  PHASE_APPLICATION,
  PHASE_CLOSING,
  PHASE_FUNDED,
  PHASE_OFFER,
  PHASE_PLACEMENT,
  PHASE_SCORECARDS,
  PHASE_UNDERWRITING,
  STATUS_DEAD,
  WORKFLOW_RELATED_DEAL_STATUSES,
  WORKFLOW_STAGES
} from "@/helpers/constants";
import { useStatuses } from "./options";
import {
  MULTI_RECORD_BLOCKS,
  PHASES_WITH_TABS,
  STAGE_GROUP_TYPE,
  WORKFLOW_BLOCKS_IDS
} from "@/helpers/constants/workflow";
import { getDealStatusName } from "@/helpers/formatting";
import type {
  ApplicationStage,
  BorrowerPlatformOptions,
  BorrowerPlatformStage,
  BorrowerPlatformState,
  FastTrackTransition,
  FastTrackUnderwriting,
  FastTrackWidget,
  IFlowRecord,
  IWorkflow,
  IWorkflowBlock,
  IWorkflowData,
  IWorkflowStage,
  IWorkflowStageOption,
  IWorkflowTab,
  WfbFunderRecord,
  WorkflowBlockIds,
  WorkflowBlocksData,
  WorkflowContent
} from "@/models/workflows";
import { useNotification } from "./notifications";
import { WorkflowValidationChain } from "@/lib/WorkflowValidators";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { usePermissions } from "@/hooks/auth";
import useApplicationsStore from "@/stores/applications";
import useNotesStore from "@/stores/notes";
import { storeToRefs } from "pinia";

import type { Entity } from "@/enums/entityProfiles";
import type { IApplicationStatus } from "@/models/options";
/** Type imports like this one from lodash are removed automatically when compiled to JS */
// eslint-disable-next-line lodash/import-scope
import { uniq, type GetFieldType } from "lodash";
import { Ability, PermissionSubject } from "@/enums/auth";
import {
  ROUTE_BORROWER_PLATFORM_BUILDER,
  WORKFLOW_TEMPLATE_ROUTE_GROUP
} from "@/router/routes";
import { isOfferStage } from "@/helpers/workflow";
import type { OfferDestinationRecord } from "@/models/offers";

interface PresetRecord {
  [templateId: string]: OfferDestinationRecord;
}

/** A type to support suffix like [0] or [1] if the provided key is an array */
type ToArrayIterable<TA> = TA extends unknown[] ? `.[${number}]` : never;

type WorkflowContentKeys = keyof WorkflowContent;
type FastTrackKeys = keyof WorkflowContent["fast_track"];
type WidgetDropOffKeys = keyof FastTrackWidget;
type FastTrackUnderwritingKeys = keyof FastTrackUnderwriting;
type BorrowerPlatformKeys = keyof BorrowerPlatformOptions;
type DocumentsSyncDownKeys = keyof NonNullable<
  IWorkflow["content"]
>["documents"]["sync"]["down"];
type ActivityHubKeys = keyof WorkflowContent["activity_hub"];

type FastTrackUnderwritingKeysWithIterables =
  `${FastTrackUnderwritingKeys}${ToArrayIterable<
    FastTrackUnderwriting[FastTrackUnderwritingKeys]
  >}`;

type BorrowerPlatformKeysWithIterables = `${Extract<
  BorrowerPlatformKeys,
  "stages" | "offer_declined_reasons"
>}${ToArrayIterable<BorrowerPlatformOptions[BorrowerPlatformKeys]>}`;

type ValidPathForWorkflowSettingsUpdateWithoutIterables =
  | keyof IWorkflow
  | `content.${WorkflowContentKeys}`
  | `content.borrower_platform.${BorrowerPlatformKeys}`
  | `content.fast_track.${FastTrackKeys}`
  | `content.fast_track.underwriting.${FastTrackUnderwritingKeys}`
  | `content.fast_track.widget.${WidgetDropOffKeys}`
  | `content.documents.sync.down.${DocumentsSyncDownKeys}`
  | `content.activity_hub.${ActivityHubKeys}`;

type GetArrayElementType<
  TPath extends ValidPathForWorkflowSettingsUpdateWithoutIterables
> =
  NonNullable<GetFieldType<IWorkflow, TPath>> extends Array<infer TElement>
    ? TElement
    : never;

export type ExtractTypeFromPath<
  TPath extends ValidPathForWorkflowSettingsUpdate
> = TPath extends `${infer Left extends
  ValidPathForWorkflowSettingsUpdateWithoutIterables}.[${number}]`
  ? GetArrayElementType<Left>
  : GetFieldType<IWorkflow, TPath>;

export type ValidPathForWorkflowSettingsUpdate =
  | ValidPathForWorkflowSettingsUpdateWithoutIterables
  | `content.fast_track.underwriting.${FastTrackUnderwritingKeysWithIterables}`
  | `content.borrower_platform.${BorrowerPlatformKeysWithIterables}`;

export type FastTrackPlacementTransitionRange = Pick<
  FastTrackTransition,
  "from" | "to"
>;

export const useWorkflowTemplates = () => {
  const { getters } = useStore();

  const templates = computed<IWorkflowData>(
    () => getters["workflows/templatesData"]
  );

  return { templates };
};

export const useActiveWorkflowTemplate = () => {
  const { getters, commit } = useStore();
  const { canPerformActionReactive } = usePermissions();
  const route = useRoute();

  const canViewWorkflowAutomation = ref(false);
  const canManageWorkflowAutomation = ref(false);
  const canEditWorkflowTemplate = ref(false);

  const activeTemplate = computed<IWorkflow>(
    () => getters["workflows/activeTemplate"]
  );

  const activeTemplateWebhooks = computed(
    () => activeTemplate.value.content?.webhooks
  );

  const activeTemplateEmailNotifications = computed(
    () => activeTemplate.value.content?.emails
  );

  const activeTemplateSmsNotifications = computed(
    () => activeTemplate.value.content?.sms_notifications
  );

  const isBorrowerPlatformBuilder = computed(
    () => route.name === ROUTE_BORROWER_PLATFORM_BUILDER
  );

  const borrowerPlatformContent = computed(
    () => activeTemplate.value.content?.borrower_platform
  );

  const selectedBorrowerPlatformStageId = computed<BorrowerPlatformStage["id"]>(
    () => getters["workflows/selectedBorrowerPlatformStageId"]
  );

  const mainStatuses = computed(
    () => activeTemplate.value?.content?.statuses ?? []
  );

  const selectedBorrowerPlatformState = computed<
    BorrowerPlatformState["type"] | null
  >(() => getters["workflows/selectedBorrowerPlatformState"]);

  const activeBorrowerPlatformState = computed(() => {
    const currentStage = borrowerPlatformContent.value?.stages.find(
      (stage) => stage.id === selectedBorrowerPlatformStageId.value
    );
    return currentStage?.states.find(
      (state) => state.type === selectedBorrowerPlatformState.value
    );
  });

  const handleWorkflowSettingsChange = <
    TPath extends ValidPathForWorkflowSettingsUpdate
  >(
    path: TPath,
    value: Partial<ExtractTypeFromPath<TPath>>
  ): void => {
    const templateData = cloneDeep(activeTemplate.value);
    set(templateData, path, value);
    commit("workflows/setActiveTemplate", { templateData });
  };

  const allSelectedFunders = computed(() => {
    const allFunderIds = activeTemplate.value.content?.stages.reduce(
      (acc: string[], stage) => {
        if (stage.type === "placement" && stage.funders?.length) {
          acc.push(...(stage.funders as string[]));
        }
        return acc;
      },
      []
    );
    return uniq(allFunderIds ?? []);
  });

  const funderPlacementPresetsMap = computed(() => {
    const presets = activeTemplate.value.content?.stages.reduce(
      (acc: Record<string, PresetRecord>, stage) => {
        if (isOfferStage(stage) && stage.funders?.length) {
          stage.funders.forEach((funder) => {
            const currentFunderRecord = acc[funder.id] || {};
            for (let i = 0; i < funder.offer_templates.length; i++) {
              const template = funder.offer_templates[i];
              if (template.enabled) {
                currentFunderRecord[template.id] = template.destinations[0];
              }
            }
            acc[funder.id] = currentFunderRecord;
          });
        }
        return acc;
      },
      {}
    );
    return presets ?? {};
  });

  const allSelectedOfferFunders = computed(() => {
    const allFunderRecords = activeTemplate.value.content?.stages.reduce(
      (acc: WfbFunderRecord[], stage) => {
        if (stage && isOfferStage(stage) && stage.funders?.length) {
          acc.push(...stage.funders);
        }
        return acc;
      },
      []
    );
    return allFunderRecords ?? [];
  });

  const hasStageTypeComputed = (type: ApplicationStage) => {
    return computed(() =>
      activeTemplate.value.content?.stages.some((stage) => stage.type === type)
    );
  };

  const firstStages = computed(() => {
    const stages = activeTemplate.value.content?.stages || [];
    const application = stages.find(({ type }) => type === "application");
    const placement = stages.find(({ type }) => type === "placement");
    return { application, placement };
  });

  const fastTrackPlacementTransitionRange = computed(() => {
    const { application: firstAppStage, placement: firstPlacementStage } =
      firstStages.value;
    if (!firstAppStage || !firstPlacementStage) {
      return null;
    }

    const transition: FastTrackPlacementTransitionRange = {
      from: firstAppStage.id,
      to: firstPlacementStage.id
    };

    return transition;
  });

  const fastTrackPermissions = computed(() => {
    const canView = canViewWorkflowAutomation.value;
    const canUpdate = canManageWorkflowAutomation.value;
    return { canView, canUpdate };
  });

  watchEffect(() => {
    const isRouteSupported = WORKFLOW_TEMPLATE_ROUTE_GROUP.includes(
      route.name as string
    );
    if (!activeTemplate.value?.id || !isRouteSupported) {
      return;
    }
    canViewWorkflowAutomation.value = canPerformActionReactive(
      PermissionSubject.workflowTemplate,
      Ability.viewFastTrack,
      { workflowTemplate: activeTemplate.value.id }
    ).value;
    canManageWorkflowAutomation.value = canPerformActionReactive(
      PermissionSubject.workflowTemplate,
      Ability.manageFastTrack,
      { workflowTemplate: activeTemplate.value.id }
    ).value;
    canEditWorkflowTemplate.value = canPerformActionReactive(
      PermissionSubject.workflowTemplate,
      Ability.update,
      { workflowTemplate: activeTemplate.value.id }
    ).value;
  });

  return {
    activeTemplate,
    activeTemplateWebhooks,
    activeTemplateEmailNotifications,
    activeTemplateSmsNotifications,
    borrowerPlatformContent,
    selectedBorrowerPlatformStageId,
    allSelectedFunders,
    hasStageTypeComputed,
    canEditWorkflowTemplate,
    fastTrackPermissions,
    fastTrackPlacementTransitionRange,
    firstStages,
    handleWorkflowSettingsChange,
    isBorrowerPlatformBuilder,
    mainStatuses,
    selectedBorrowerPlatformState,
    activeBorrowerPlatformState,
    allSelectedOfferFunders,
    funderPlacementPresetsMap
  };
};

export const useWorkflowFields = <T extends keyof WorkflowBlocksData>(
  blockId: T
) => {
  const { getters } = useStore();
  const route = useRoute();

  const visibleFields = computed<Array<string>>(() =>
    route.meta.allVisibleFields
      ? getters["workflows/allVisibleFieldsByIdsForBlock"](blockId)
      : getters["workflows/visibleFieldsForActiveTabByBlockId"](blockId)
  );

  const fieldData = computed<WorkflowBlocksData[typeof blockId]>(() => {
    const defaultData = getters["workflows/templateFieldsById"](blockId);
    if (defaultData) {
      return defaultData;
    }
    return MULTI_RECORD_BLOCKS.includes(blockId) ? [] : {};
  });

  const isFieldVisible = (field: string | undefined) => {
    return !!field && !!visibleFields.value?.includes(field);
  };

  return {
    isFieldVisible,
    fieldData
  };
};

export const useWorkflowStagesInfo = () => {
  const { getters } = useStore();

  return computed<IWorkflowStageOption[]>(
    () => getters["options/workflowStagesInfo"]
  );
};

export const useActiveWorkflowTemplateStages = () => {
  const { getters, commit, dispatch } = useStore();
  const { activeDeal: deal } = useDealsBase();
  const { apiStatuses } = useStatuses();
  const applicationsStore = useApplicationsStore();
  const notesStore = useNotesStore();
  const { shouldFetchBusinessNotes, shouldFetchOffers } =
    storeToRefs(applicationsStore);

  const activeTemplateStages = computed<IWorkflowStage[]>(
    () => getters["workflows/activeTemplateStages"]
  );

  const hasPlacementStage = computed(
    () =>
      !!activeTemplateStages.value.find(
        (stage) => stage.type === WORKFLOW_STAGES.PLACEMENT
      )
  );

  const lastWFLstage = computed(
    () =>
      activeTemplateStages.value[activeTemplateStages.value.length - 1]?.name
  );

  const activeTemplateFlow = computed<IFlowRecord[]>(
    () => getters["workflows/activeTemplateFlow"]
  );

  const setActiveUiStage = async (phase?: string) => {
    let workflowStage: IWorkflowStage | undefined = undefined;
    if (phase) {
      workflowStage = activeTemplateStages.value.find(
        ({ name }) => name === phase
      );
    } else {
      workflowStage = activeTemplateStages.value[indexOfCurrentPhase.value];
    }
    commit("workflows/setActiveStage", workflowStage);

    if (!workflowStage?.type) {
      return;
    }

    if (
      workflowStage.type === WORKFLOW_STAGES.APPLICATION &&
      shouldFetchBusinessNotes.value
    ) {
      applicationsStore.updateFetchServiceStatus(["businessNotes"], false);
      await notesStore.getNotes({
        application_ids: [deal.value.id]
      });
    }

    const nonOfferSteps = [
      WORKFLOW_STAGES.APPLICATION,
      WORKFLOW_STAGES.UNDERWRITING,
      WORKFLOW_STAGES.SCORECARDS
    ];

    const isOfferStage = nonOfferSteps.every(
      (step) => step !== workflowStage?.type
    );

    if (isOfferStage && shouldFetchOffers.value) {
      applicationsStore.updateFetchServiceStatus(["offers"], false);
      await dispatch("applications/getOffers");
    }
  };

  const getAllBlocksForStage = (
    stageType: ApplicationStage,
    unique = false
  ) => {
    const filteredBlocks = activeTemplateStages.value.reduce(
      (applicationStageTabs: IWorkflowBlock[], currentStage) => {
        if (currentStage.type === stageType && currentStage.tabs?.length) {
          currentStage.tabs.forEach((tab) => {
            applicationStageTabs.push(...tab.blocks);
          });
        }
        return applicationStageTabs;
      },
      []
    );
    if (unique) {
      const uniqueBlocks: Partial<Record<WorkflowBlockIds, boolean>> = {};
      return filteredBlocks.filter((block) => {
        if (!uniqueBlocks[block.id]) {
          uniqueBlocks[block.id] = true;
          return true;
        }
        return false;
      });
    }
    return filteredBlocks;
  };
  const activeWorkflowPhases = computed(() =>
    activeTemplateStages.value.map(({ name }) => name)
  );

  const currentWorkflowPhaseIndx = computed(() =>
    activeTemplateFlow.value.findIndex(
      (stage) =>
        stage.id === deal.value.stage_id ||
        stage.stage_id === deal.value.stage_id
    )
  );

  const currentPhaseName = computed(() => {
    let name = "";
    if (
      !deal.value.status ||
      WORKFLOW_RELATED_DEAL_STATUSES.includes(
        deal.value.status as Partial<IApplicationStatus & null>
      )
    ) {
      name =
        activeTemplateFlow.value[currentWorkflowPhaseIndx.value]?.name || "";
    } else {
      name = apiStatuses.value[deal.value.status || ""] ?? PHASE_APPLICATION;
    }

    if (deal.value.is_data_capture) {
      name = PHASE_APPLICATION;
    }

    if (deal.value.is_equipment_rental && name === "Complete") {
      name = "Approved";
    }

    if (deal.value.status === STATUS_DEAD) {
      name = getDealStatusName(
        deal.value?.death?.previous_status || 0
      ).status_description;
    }

    return name;
  });

  const indexOfCurrentPhase = computed(() => {
    const index = activeWorkflowPhases.value.findIndex(
      (phase) => phase.toLowerCase() === currentPhaseName.value.toLowerCase()
    );

    return index < 0 ? currentWorkflowPhaseIndx.value : index;
  });

  const COMPONENTS_DATA_MAP = computed(() => {
    const data = {
      [WORKFLOW_BLOCKS_IDS.insurance]: { deal: deal.value },
      [WORKFLOW_BLOCKS_IDS.business_notes]: { fetchNotes: false },
      [WORKFLOW_BLOCKS_IDS.stips]: { showTypes: true }
    };
    return data as Record<WorkflowBlockIds, (typeof data)[keyof typeof data]>;
  });

  return {
    activeTemplateStages,
    hasPlacementStage,
    activeWorkflowPhases,
    activeTemplateFlow,
    currentPhaseName,
    currentWorkflowPhaseIndx,
    indexOfCurrentPhase,
    getAllBlocksForStage,
    lastWFLstage,
    setActiveUiStage,
    COMPONENTS_DATA_MAP
  };
};

export const useActiveStage = () => {
  const { getters } = useStore();
  const { activeTemplateFlow } = useActiveWorkflowTemplateStages();

  const activeStage = computed<IWorkflowStage | undefined>(
    () => getters["workflows/activeStage"]
  );

  const stageGroup = computed<string>(() =>
    PHASES_WITH_TABS.includes(activeStage.value?.type || "")
      ? STAGE_GROUP_TYPE.WITH_BLOCKS
      : STAGE_GROUP_TYPE.WITHOUT_BLOCKS
  );

  const isApplicationStage = computed<boolean>(
    () =>
      activeStage.value?.type.toLowerCase() === PHASE_APPLICATION.toLowerCase()
  );

  const isUnderwritingStage = computed<boolean>(
    () =>
      activeStage.value?.type.toLowerCase() === PHASE_UNDERWRITING.toLowerCase()
  );

  const isScorecardsStage = computed<boolean>(
    () =>
      activeStage.value?.type?.toLowerCase() === PHASE_SCORECARDS.toLowerCase()
  );

  const isPlacementStage = computed<boolean>(
    () =>
      activeStage.value?.type?.toLowerCase() === PHASE_PLACEMENT.toLowerCase()
  );

  const isOfferStage = computed<boolean>(
    () => activeStage.value?.type?.toLowerCase() === PHASE_OFFER.toLowerCase()
  );

  const isClosingStage = computed<boolean>(
    () => activeStage.value?.type?.toLowerCase() === PHASE_CLOSING.toLowerCase()
  );

  const isFundedStage = computed<boolean>(
    () => activeStage.value?.type?.toLowerCase() === PHASE_FUNDED.toLowerCase()
  );

  const activeStageFlowId = computed(
    () =>
      activeTemplateFlow.value.find(
        (stage) => stage.stage_id === activeStage.value?.id
      )?.id
  );

  const isManagedByLendflow = computed(
    () => !!activeStage.value?.management?.lendflow
  );

  const liveStatuses = computed(
    () => activeStage.value?.statuses?.filter((status) => status.live) ?? []
  );

  const deadStatuses = computed(
    () => activeStage.value?.statuses?.filter((status) => !status.live) ?? []
  );

  return {
    activeStage,
    stageGroup,
    isApplicationStage,
    isUnderwritingStage,
    isScorecardsStage,
    isPlacementStage,
    isOfferStage,
    isClosingStage,
    activeStageFlowId,
    isManagedByLendflow,
    liveStatuses,
    deadStatuses,
    isFundedStage
  };
};

export const useActiveEntityType = () => {
  const { getters } = useStore();

  const activeEntityType = computed<Entity>(
    () => getters["workflows/activeEntityType"]
  );

  return { activeEntityType };
};

export const useActiveTab = () => {
  const { getters } = useStore();

  const activeTab = computed<IWorkflowTab | undefined>(() => {
    return getters["workflows/activeTab"];
  });

  const getIsBlockAlreadyPresent = (id: WorkflowBlockIds) => {
    const blocks = activeTab.value?.blocks || [];
    return blocks.findIndex((block) => block.id === id) > -1;
  };

  return { activeTab, getIsBlockAlreadyPresent };
};

export const useWorkflowValidation = () => {
  const { activeTemplateStages } = useActiveWorkflowTemplateStages();
  const { allSelectedFunders, hasStageTypeComputed } =
    useActiveWorkflowTemplate();
  const { t } = useI18n();
  const { showMessage } = useNotification();

  const hasPlacementStage = hasStageTypeComputed(WORKFLOW_STAGES.PLACEMENT);

  const errorBag = computed(() => {
    const errorMessages: string[] = [];
    const dataCollectionStages = activeTemplateStages.value.filter(
      (stage) => stage.type === WORKFLOW_STAGES.APPLICATION
    );

    if (!dataCollectionStages.length) {
      errorMessages.push(t("WORKFLOW.ERROR_MESSAGES.NO_DATA_COLLECTION_STAGE"));
    }

    if (activeTemplateStages.value[0].type !== "application") {
      errorMessages.push(
        t("WORKFLOW.ERROR_MESSAGES.FIRST_STAGE_MUST_BE_DATA_COLLECTION")
      );
    }

    if (hasPlacementStage.value && !allSelectedFunders.value.length) {
      errorMessages.push(t("WORKFLOW.ERROR_MESSAGES.NO_PLACEMENT_FUNDERS"));
    }

    activeTemplateStages.value.forEach((stage) => {
      const messages = WorkflowValidationChain.run(stage);
      errorMessages.push(...messages);
    });

    return errorMessages;
  });

  const isValid = computed(() => !errorBag.value.length);

  const toastValidationMessages = () => {
    if (!isValid.value) {
      showMessage(errorBag.value.join("\n"), "error", "", { duration: 10000 });
    }
  };

  return { errorBag, isValid, toastValidationMessages };
};

export const useWorkflowTabs = () => {
  const { activeStage } = useActiveStage();
  const { activeTemplate } = useActiveWorkflowTemplate();
  const { getters, dispatch } = useStore();
  const templateFields = computed<WorkflowBlocksData>(
    () => getters["workflows/templateFields"]
  );

  const applicationBlockIds = computed(
    () =>
      activeTemplate.value?.content?.stages?.reduce(
        (acc: (keyof WorkflowBlocksData)[], { type, tabs }) => {
          if (type === WORKFLOW_STAGES.APPLICATION) {
            const ids =
              tabs?.flatMap(({ blocks }) => blocks.map(({ id }) => id)) ?? [];
            acc.push(...(ids as (keyof WorkflowBlocksData)[]));
          }
          return acc;
        },
        []
      ) ?? []
  );

  const dataRequestedFor = ref<Record<string, boolean>>({});

  const tabsLoadingState = computed(
    () =>
      activeStage.value?.tabs?.reduce((acc: Record<string, boolean>, tab) => {
        acc[tab.name] = false;
        for (const block of tab.blocks) {
          if (
            block.fields &&
            !templateFields.value[block.id as keyof WorkflowBlocksData]
          ) {
            acc[tab.name] = true;
            break;
          }
        }
        return acc;
      }, {}) ?? {}
  );

  const fetchFields = async (
    blockIds: (keyof WorkflowBlocksData)[],
    owerwriteStoredData = false
  ) => {
    const payload: (keyof WorkflowBlocksData)[] = [];
    blockIds.forEach((blockId) => {
      if (
        //only checking application stage blocks since only they have fields.
        applicationBlockIds.value.includes(blockId) &&
        (!templateFields.value[blockId] || owerwriteStoredData) &&
        !dataRequestedFor.value[blockId]
      ) {
        dataRequestedFor.value[blockId] = true;
        payload.push(blockId);
      }
    });
    if (!payload.length) return;
    dispatch("workflows/getBlocksData", payload);
  };

  const getApplicationStageData = async () => {
    activeStage.value?.tabs?.forEach((tab) => {
      const blockIds = tab.blocks.reduce(
        (acc: (keyof WorkflowBlocksData)[], block) => {
          if (block.fields) {
            acc.push(block.id as keyof WorkflowBlocksData);
          }
          return acc;
        },
        []
      );
      if (!blockIds.length) {
        return;
      }
      fetchFields(blockIds);
    });
  };

  return {
    getApplicationStageData,
    tabsLoadingState,
    fetchFields
  };
};
