const AccountView = () => import("@/views/profile/AccountView.vue");
const AnalyticsServiceReport = () =>
  import("@/views/reports/AnalyticsServiceReport.vue");
const ApiCalls = () => import("@/views/reports/ApiCalls.vue");
const ApplicationLinks = () => import("@/views/deals/ApplicationLinks.vue");
const AttributesView = () => import("@/views/CustomAttributesView.vue");
const BorrowerPlatformBuilder = () =>
  import(
    "@/components/deals/workflow/BorrowerPlatform/BorrowerPlatformBuilder.vue"
  );
const BorrowerPlatformIntegrationView = () =>
  import("@/views/integrations/BorrowerPlatformIntegrationView.vue");
const BusinessDeals = () =>
  import("@/views/businesses/BusinessDeals/BusinessDeals.vue");
const BusinessEntityProfile = () =>
  import("@/views/businesses/BusinessEntityProfile.vue");
const BusinessProfileInfo = () =>
  import("@/views/businesses/BusinessProfileInfo.vue");
const BusinessTrackingTab = () =>
  import("@/views/businesses/BusinessTrackingTab.vue");
const BusinessesView = () => import("@/views/businesses/BusinessesView.vue");
const ClientOrganizationView = () =>
  import("@/views/profile/ClientOrganizationView.vue");
const ClientSettingsView = () =>
  import("@/views/profile/components/ClientSettingsView.vue");
const ClosingDocsView = () => import("@/views/ClosingDocsView.vue");
const ContractBuilderForm = () =>
  import("@/views/profile/components/ContractBuilderForm.vue");
const ContractBuilderView = () =>
  import("@/views/profile/ContractBuilderView.vue");
const CountriesView = () => import("@/views/profile/CountriesView.vue");
const CreateOrResetPasswordView = () =>
  import("@/views/authentication/CreateOrResetPasswordView.vue");
const CustomAttributeBuilderView = () =>
  import("@/views/CustomAttributeBuilderView.vue");
const CustomReport = () => import("@/views/reports/CustomReport.vue");
const CustomerSuccessNorthstarMetrics = () =>
  import("@/views/reports/CustomerSuccessNorthstarMetrics.vue");
const CustomizeEmailTemplateView = () =>
  import("@/views/customize/CustomizeEmailTemplateView.vue");
const ConsentTemplateList = () =>
  import("@/views/customize/consentTemplate/ConsentTemplateList.vue");
const ConsentTemplateEditView = () =>
  import("@/views/customize/consentTemplate/ConsentTemplateEditView.vue");
const CustomizeView = () => import("@/views/customize/CustomizeView.vue");
const DataProvidersView = () => import("@/views/DataProvidersView.vue");
const DealDocumentsView = () => import("@/views/deals/DealDocumentsView.vue");
const DealProgressView = () => import("@/views/deals/DealProgressView.vue");
const DealsBaseView = () => import("@/views/deals/DealsBaseView.vue");
const DealsView = () => import("@/views/deals/DealsView.vue");
const EmailBranding = () => import("@/views/customize/EmailBranding.vue");
const EmailBrandingView = () =>
  import("@/views/customize/EmailBrandingView.vue");
const EmailIntegrationView = () =>
  import("@/views/profile/EmailIntegrationView.vue");
const EmailNotifications = () =>
  import("@/components/deals/workflow/notifications/EmailNotifications.vue");
const EmailTemplates = () =>
  import("@/views/profile/components/EmailTemplates.vue");
const EmailTemplatesEdit = () =>
  import("@/views/profile/components/EmailTemplatesEdit.vue");
const EmbedNorthstarMetrics = () =>
  import("@/views/reports/EmbedNorthstarMetrics.vue");
const EmbeddedClientReport = () =>
  import("@/views/reports/EmbeddedClientReport.vue");
const EmployeesView = () => import("@/views/profile/EmployeesView.vue");
const EntityRelationsView = () => import("@/views/EntityRelationsView.vue");
const ErcReports = () => import("@/views/reports/ErcReports.vue");
const FlowNorthstarMetrics = () =>
  import("@/views/reports/FlowNorthstarMetrics.vue");
const ForgotPasswordView = () =>
  import("@/views/authentication/ForgotPasswordView.vue");
const FullAppView = () => import("@/views/deals/FullAppView.vue");
const FunderInfoView = () => import("@/views/funders/FunderInfoView.vue");
const FunderOrganizationView = () =>
  import("@/views/profile/FunderOrganizationView.vue");
const FundersView = () => import("@/views/funders/FundersView.vue");
const FundingSalesNorthstarMetrics = () =>
  import("@/views/reports/FundingSalesNorthstarMetrics.vue");
const FuseNorthstarMetrics = () =>
  import("@/views/reports/FuseNorthstarMetrics.vue");
const IndividualProfile = () =>
  import("@/views/individuals/IndividualProfile.vue");
const IndividualProfilePersonalInfo = () =>
  import("@/views/individuals/IndividualProfilePersonalInfo.vue");
const IndividualsView = () => import("@/views/individuals/IndividualsView.vue");
const IntegrationsView = () =>
  import("@/views/integrations/IntegrationsView.vue");
const LenderIntegrationsView = () =>
  import("@/views/LenderIntegrationsView.vue");
const LendflowNorthstarMetrics = () =>
  import("@/views/reports/LendflowNorthstarMetrics.vue");
const LendingServiceProvidersView = () =>
  import("@/views/LendingServiceProvidersView.vue");
const LoginView = () => import("@/views/authentication/LoginView.vue");
const LogoutView = () => import("@/views/Logout.vue");
const MarketingNorthstarMetrics = () =>
  import("@/views/reports/MarketingNorthstarMetrics.vue");
const NotificationsView = () => import("@/views/profile/NotificationsView.vue");
const OrchestrationTemplateView = () =>
  import("@/views/profile/OrchestrationTemplateView.vue");
const OrchestrationView = () => import("@/views/profile/OrchestrationView.vue");
const OrganizationView = () => import("@/views/profile/OrganizationView.vue");
const OriginationIntegrationsView = () =>
  import("@/views/OriginationIntegrationsView.vue");
const PageNotFound = () => import("@/views/PageNotFound.vue");
const PartnerSalesNorthstarMetrics = () =>
  import("@/views/reports/PartnerSalesNorthstarMetrics.vue");
const ProfileBaseView = () => import("@/views/profile/ProfileBaseView.vue");
const RegisterView = () => import("@/views/authentication/RegisterView.vue");
const ReportsView = () => import("@/views/reports/ReportsView.vue");
const RouterView = () => import("@/views/RouterView.vue");
const ScorecardsBacktestReportBuilder = () =>
  import("@/views/profile/ScorecardsBacktestReportBuilder.vue");
const ScorecardsBaseView = () =>
  import("@/views/profile/ScorecardsBaseView.vue");
const ScorecardsBuilderView = () =>
  import("@/views/profile/ScorecardsBuilderView.vue");
const ScorecardsGroupReportView = () =>
  import("@/views/profile/ScorecardsGroupReportView.vue");
const ScorecardsGroupView = () =>
  import("@/views/profile/ScorecardsGroupView.vue");
const ScorecardsOverview = () =>
  import("@/views/profile/ScorecardsOverview.vue");
const ScorecardsReportView = () =>
  import("@/views/profile/ScorecardsReportView.vue");
const ScorecardsTemplateView = () =>
  import("@/views/profile/ScorecardsTemplateView.vue");
const SmsNotifications = () =>
  import("@/components/deals/workflow/notifications/SmsNotifications.vue");
const SmsTemplates = () =>
  import("@/views/profile/components/SmsTemplates.vue");
const SmsTemplatesEdit = () =>
  import("@/views/profile/components/SmsTemplatesEdit.vue");
const StipsView = () => import("@/views/profile/StipsView.vue");
const TrackingView = () => import("@/views/deals/TrackingView.vue");
const UnderwritingLenderRelationsNorthstarMetrics = () =>
  import("@/views/reports/UnderwritingLenderRelationsNorthstarMetrics.vue");
const WebhookNotifications = () =>
  import("@/components/deals/workflow/notifications/WebhookNotifications.vue");
const WhitelabelView = () => import("@/views/profile/WhitelabelView.vue");
const WidgetBranding = () => import("@/views/customize/WidgetBranding.vue");
const WidgetBrandingTemplate = () =>
  import("@/views/customize/WidgetBrandingTemplate.vue");
const WidgetIntegrationView = () =>
  import("@/views/integrations/WidgetIntegrationView.vue");
const WorkflowBuilder = () =>
  import("@/components/deals/workflow/WorkflowBuilder.vue");
const WorkflowBuilderContent = () =>
  import("@/components/deals/workflow/WorkflowBuilderContent.vue");
const WorkflowBuilderDocumentSyncing = () =>
  import("@/components/deals/workflow/WorkflowBuilderDocumentSyncing.vue");
const WorkflowBuilderEmailSubmission = () =>
  import("@/components/deals/workflow/WorkflowBuilderEmailSubmission.vue");
const WorkflowBuilderActivityHub = () =>
  import("@/components/deals/workflow/WorkflowBuilderActivityHub.vue");
const WorkflowBuilderSettings = () =>
  import("@/components/deals/workflow/WorkflowBuilderSettings.vue");
const WorkflowBuilderSettingsAutomation = () =>
  import("@/components/deals/workflow/WorkflowBuilderSettingsAutomation.vue");
const WorkflowBuilderSettingsView = () =>
  import("@/views/WorkflowBuilderSettingsView.vue");
const WorkflowBuilderWidgetAndBranding = () =>
  import("@/components/deals/workflow/WorkflowBuilderWidgetAndBranding.vue");
const WorkflowTemplateNew = () =>
  import("@/views/profile/WorkflowTemplateNew.vue");
const WorkflowView = () => import("@/views/profile/WorkflowView.vue");

const ActivityHubView = () => import("@/views/ActivityHubView.vue");

import { IClientEmbedReportOption } from "@/models/clients";
import {
  ADMIN,
  CLIENT_ADMIN,
  CLIENT_FUNDING_ADVISOR,
  CLIENT_ROLE_GROUP,
  CLIENT_UNDERWRITER,
  FUNDER_ADMIN,
  FUNDER_ROLE_GROUP,
  FUNDING_ADVISOR,
  LENDFLOW_ROLE_GROUP,
  SUPER_ADMIN,
  UNDERWRITER,
  USER
} from "@/helpers/constants";
import { Entity } from "@/enums/entityProfiles";
import { OrchestrationType } from "@/enums/orchestration";
import type { RouteRecordRaw } from "vue-router";
import {
  mockedFeatureRouteMiddleware,
  routeAccessCheck
} from "@/router/validations";
import type { Role } from "@/models/options";
import i18n from "@/i18n";
import store from "@/store";
import isEmpty from "lodash/isEmpty";

export const ROUTE_ACTIVITY_HUB = "ActivityHub" as const;
export const ROUTE_ACTIVITY_HUB_TASKS = "Tasks" as const;
export const ROUTE_ACCOUNT = "Account" as const;
export const ROUTE_BORROWER_PLATFORM_BUILDER =
  "BorrowerPlatformBuilder" as const;
export const ROUTE_BUSINESSES = "Businesses" as const;
export const ROUTE_BUSINESS_ENTITY_PROFILE = "BusinessEntityProfile" as const;
export const ROUTE_BUSINESS_PROFILE_DEALS = "BusinessProfileDeals" as const;
export const ROUTE_BUSINESS_PROFILE_INFO = "BusinessProfileInfo" as const;
export const ROUTE_BUSINESS_RELATIONS = "BusinessRelations" as const;
export const ROUTE_BUSINESS_TRACKING = "BusinessTracking" as const;
export const ROUTE_CLIENT_SETTINGS = "ClientSettings" as const;
export const ROUTE_CONTRACTS = "Contracts" as const;
export const ROUTE_CONTRACT_BUILDER = "ContractBuilder" as const;
export const ROUTE_CONTRACT_BUILDER_EDIT = "ContractBuilderEdit" as const;
export const ROUTE_CONTRACT_BUILDER_NEW = "ContractBuilderNew" as const;
export const ROUTE_CUSTOM_ATTRIBUTES_BUILDER_VIEW =
  "CustomAttributeBuilderView" as const;
export const ROUTE_DEALS = "Deals" as const;
export const ROUTE_DEAL_ACTIVITY = "DealActivity" as const;
export const ROUTE_DEAL_PROGRESS = "DealProgress" as const;
export const ROUTE_DOCUMENTS = "Documents" as const;
export const ROUTE_EMAIL_TEMPLATES = "EmailTemplates" as const;
export const ROUTE_EMAIL_TEMPLATES_EDIT = "EmailTemplatesEdit" as const;
export const ROUTE_EMAIL_TEMPLATES_NEW = "EmailTemplatesNew" as const;
export const ROUTE_ERC_REPORTS = "ErcReports" as const;
export const ROUTE_FUNDER_SETTINGS = "FunderSettings" as const;
export const ROUTE_INDIVIDUAL_PROFILE = "IndividualProfile" as const;
export const ROUTE_INDIVIDUAL_PROFILE_DEALS = "IndividualProfileDeals" as const;
export const ROUTE_INDIVIDUAL_PROFILE_PERSONAL_INFO =
  "IndividualProfilePersonalInfo" as const;
export const ROUTE_INDIVIDUAL_PROFILE_TRACKING =
  "IndividualProfileTrackingTab" as const;
export const ROUTE_INDIVIDUAL_RELATIONS = "IndividualRelations" as const;
export const ROUTE_INTEGRATIONS = "Integrations" as const;
export const ROUTE_INTEGRATIONS_BORROWER_PLATFORM =
  "BorrowerPlatformIntegration" as const;
export const ROUTE_INTEGRATIONS_DATA_PROVIDER = "DataIntegration" as const;
export const ROUTE_INTEGRATIONS_FUNDER = "FunderIntegration" as const;
export const ROUTE_INTEGRATIONS_ORIGINATION = "OriginationIntegration" as const;
export const ROUTE_INTEGRATIONS_SERVICE_PROVIDER =
  "LendingServiceProvider" as const;
export const ROUTE_INTEGRATIONS_WIDGET = "WidgetIntegration" as const;
export const ROUTE_ORCHESTRATION_LIST = "Orchestration" as const;
export const ROUTE_ORCHESTRATION_TEMPLATE =
  "OrchestrationTemplateView" as const;
export const ROUTE_ORGANIZATION = "Organization" as const;
export const ROUTE_PAGE_NOT_FOUND = "PageNotFound" as const;
export const ROUTE_SCORECARDS_BACKTEST_REPORT_EDIT =
  "ScorecardsBacktestReportEdit" as const;
export const ROUTE_SCORECARDS_BACKTEST_REPORT_VIEW =
  "ScorecardsBacktestReportView" as const;
export const ROUTE_SCORECARDS_BUILDER_VIEW = "ScorecardsBuilder" as const;
export const ROUTE_SCORECARDS_GROUP_NEW = "ScorecardsGroupNew" as const;
export const ROUTE_SMS_TEMPLATES_VIEW = "SmsTemplatesView" as const;
export const ROUTE_SMS_TEMPLATES = "SmsTemplates" as const;
export const ROUTE_SMS_TEMPLATES_EDIT = "SmsTemplatesEdit" as const;
export const ROUTE_SMS_TEMPLATES_NEW = "SmsTemplatesNew" as const;
export const ROUTE_WIDGET_BRANDING = "WidgetBranding" as const;
export const ROUTE_WIDGET_BRANDING_TEMPLATE = "WidgetBrandingTemplate" as const;
export const ROUTE_WIDGET_BRANDING_TEMPLATE_CREATE =
  "WidgetBrandingTemplateCreate" as const;
export const ROUTE_WIDGET_BRANDING_CONSENT_TEMPLATE_LIST =
  "WidgetBrandingConsentTemplateList" as const;
export const ROUTE_WIDGET_BRANDING_CONSENT_TEMPLATE_EDIT =
  "WidgetBrandingConsentTemplateEditor" as const;
export const ROUTE_WORKFLOW_BUILDER = "WorkflowBuilder" as const;
export const ROUTE_WORKFLOW_BUILDER_CONTENT = "WorkflowBuilderContent" as const;
export const ROUTE_WORKFLOW_BUILDER_NOTIFICATIONS_EMAIL =
  "WorkflowBuilderNotificationsEmail" as const;
export const ROUTE_WORKFLOW_BUILDER_NOTIFICATIONS_SMS =
  "WorkflowBuilderNotificationsSms" as const;
export const ROUTE_WORKFLOW_BUILDER_NOTIFICATIONS_WEBHOOKS =
  "WorkflowBuilderNotificationsWebhooks" as const;
export const ROUTE_WORKFLOW_BUILDER_SETTINGS =
  "WorkflowBuilderSettings" as const;
export const ROUTE_WORKFLOW_BUILDER_SETTINGS_VIEW =
  "WorkflowBuilderSettingsView" as const;
export const ROUTE_WORKFLOW_BUILDER_SUBMISSION =
  "WorkflowBuilderEmailSubmission" as const;
export const ROUTE_WORKFLOW_BUILDER_DOC_SYNC =
  "WorkflowBuilderDocSync" as const;
export const ROUTE_WORKFLOW_BUILDER_ACTIVITY_HUB =
  "WorkflowBuilderActivityHub" as const;
export const ROUTE_WORKFLOW_BUILDER_WIDGET_BRANDING =
  "WorkflowBuilderWidgetAndBranding" as const;
export const ROUTE_WORKFLOW_BUILDER_WORKFLOW_AUTOMATION =
  "WorkflowBuilderWorkflowAutomation" as const;
export const ROUTE_WORKFLOW_ORCHESTRATION = "WorkflowOrchestration" as const;
export const ROUTE_WORKFLOW_TEMPLATE_LIST =
  "WorkflowBuilderTemplatesList" as const;
export const ROUTE_WORKFLOW_TEMPLATE_NEW =
  "WorkflowBuilderTemplateNew" as const;

export const WORKFLOW_TEMPLATE_ROUTE_GROUP = [
  ROUTE_BORROWER_PLATFORM_BUILDER,
  ROUTE_WORKFLOW_BUILDER,
  ROUTE_WORKFLOW_BUILDER_CONTENT,
  ROUTE_WORKFLOW_BUILDER_DOC_SYNC,
  ROUTE_WORKFLOW_BUILDER_NOTIFICATIONS_EMAIL,
  ROUTE_WORKFLOW_BUILDER_NOTIFICATIONS_SMS,
  ROUTE_WORKFLOW_BUILDER_NOTIFICATIONS_WEBHOOKS,
  ROUTE_WORKFLOW_BUILDER_SETTINGS,
  ROUTE_WORKFLOW_BUILDER_SETTINGS_VIEW,
  ROUTE_WORKFLOW_BUILDER_SUBMISSION,
  ROUTE_WORKFLOW_BUILDER_WIDGET_BRANDING,
  ROUTE_WORKFLOW_BUILDER_WORKFLOW_AUTOMATION
] as string[];
const t = i18n.global.t;

const orchestrationAcl: Array<Role> = [
  CLIENT_ADMIN,
  CLIENT_UNDERWRITER,
  ADMIN,
  SUPER_ADMIN,
  FUNDING_ADVISOR,
  UNDERWRITER,
  CLIENT_FUNDING_ADVISOR
];

export const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Home",
    redirect: "/deals"
  },
  {
    path: "/funding-partners",
    name: "Funder-Base",
    component: RouterView,
    meta: { acl: FUNDER_ROLE_GROUP },
    children: [
      {
        path: "",
        name: "FundingPartnersRedirect",
        redirect: "/funding-partners/login"
      },
      {
        path: "details",
        name: "Funder-Details",
        component: FunderInfoView
      }
    ]
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutView
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    alias: "/funding-partners/login",
    meta: { title: t("AUTH.SIGN_IN") }
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterView,
    alias: "/funding-partners/register",
    meta: { title: t("AUTH.REGISTER") }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordView,
    alias: "/funding-partners/forgot-password",
    meta: { title: t("AUTH.FORGOT_PASS") }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: CreateOrResetPasswordView,
    alias: "/funding-partners/reset-password",
    meta: { title: t("AUTH.RESET_PASSWORD") }
  },
  {
    path: "/invitation",
    name: "CreatePassword",
    component: CreateOrResetPasswordView,
    alias: "/funding-partners/invitation"
  },
  {
    path: "/expired-password",
    name: "RenewPassword",
    component: CreateOrResetPasswordView,
    alias: "/funding-partners/expired-password"
  },
  {
    path: "/businesses",
    name: ROUTE_BUSINESSES,
    component: BusinessesView,
    meta: { title: t("GENERAL.BUSINESSES") }
  },
  {
    path: "/businesses/profile/:id",
    name: ROUTE_BUSINESS_ENTITY_PROFILE,
    component: BusinessEntityProfile,
    children: [
      {
        path: "info",
        name: ROUTE_BUSINESS_PROFILE_INFO,
        component: BusinessProfileInfo
      },
      {
        path: "deals",
        name: ROUTE_BUSINESS_PROFILE_DEALS,
        component: BusinessDeals,
        props: {}
      },
      {
        path: "relations",
        name: ROUTE_BUSINESS_RELATIONS,
        component: EntityRelationsView
      },
      {
        path: "tracking",
        name: ROUTE_BUSINESS_TRACKING,
        component: BusinessTrackingTab,
        props: {}
      }
    ]
  },
  {
    path: "/deals",
    name: ROUTE_DEALS,
    component: DealsView,
    beforeEnter(to, from) {
      // The `backTo` query param from the previous route included deals,
      // however, no search params were provided. Clear active filters.
      if (
        from.query.backTo?.includes("deals") &&
        Object.keys(to.query).length < 1
      ) {
        store.commit("applications/setActiveFilterId", -1);
      }
    },
    meta: {
      title: t("DEALS.TITLE"),
      options: ["application", "workflow_templates"]
    }
  },
  {
    path: "/deals/:id",
    name: "DealsBaseView",
    component: DealsBaseView,
    meta: {
      acl: [...LENDFLOW_ROLE_GROUP, ...CLIENT_ROLE_GROUP, ...FUNDER_ROLE_GROUP]
    },
    children: [
      {
        path: "progress",
        name: ROUTE_DEAL_PROGRESS,
        component: DealProgressView,
        meta: {
          options: ["application", "workflow_templates"]
        }
      },
      {
        path: "full",
        name: "FullApp",
        component: FullAppView,
        meta: {
          allVisibleFields: true,
          options: ["application"]
        }
      },
      {
        path: "activity",
        name: ROUTE_DEAL_ACTIVITY,
        component: ActivityHubView,
        meta: { options: ["application"] }
      },
      {
        path: "documents",
        name: "Documents",
        component: DealDocumentsView,
        meta: {
          options: ["application"]
        }
      },
      {
        path: "tracking",
        name: "Tracking",
        component: TrackingView,
        meta: {
          options: ["application"]
        }
      },
      {
        path: "application-links",
        name: "ApplicationLinks",
        component: ApplicationLinks,
        meta: {
          options: ["application", "workflow_templates"]
        }
      }
    ]
  },
  {
    path: "/profile",
    name: "ProfileBaseView",
    component: ProfileBaseView,
    children: [
      {
        path: "account",
        name: ROUTE_ACCOUNT,
        component: AccountView,
        meta: { title: t("ACCOUNT.TITLE") }
      },
      {
        path: "organization",
        name: ROUTE_ORGANIZATION,
        component: OrganizationView,
        meta: { title: t("ORGANIZATION.TITLE"), acl: LENDFLOW_ROLE_GROUP },
        children: [
          {
            path: "clients/:id",
            name: ROUTE_CLIENT_SETTINGS,
            component: ClientSettingsView,
            meta: { options: ["workflow_templates"] }
          },
          {
            path: "funders/:id",
            name: ROUTE_FUNDER_SETTINGS,
            component: FunderOrganizationView
          }
        ]
      },
      {
        path: "client/organization",
        name: "ClientOrganization",
        component: ClientOrganizationView,

        meta: {
          acl: CLIENT_ROLE_GROUP,
          title: t("SIDEBAR.PROFILE.MY_ORGANIZATION"),
          options: ["workflow_templates"]
        }
      },
      {
        path: "funding-partners/organization",
        name: "FunderOrganization",
        component: FunderOrganizationView,
        meta: { acl: [FUNDER_ADMIN], title: t("ORGANIZATION.TITLE") }
      },
      {
        path: "notifications",
        name: "Notifications",
        component: NotificationsView,
        meta: { acl: CLIENT_ROLE_GROUP, title: t("NOTIFICATIONS.TITLE") }
      },
      {
        path: "email-integration",
        name: "EmailIntegration",
        component: EmailIntegrationView,
        meta: { acl: [CLIENT_ADMIN], title: t("EMAIL_INTEGRATION.TITLE") }
      },
      {
        path: "whitelabel/:clientId/:whitelabelId",
        name: "WhitelabelView",
        component: WhitelabelView,
        meta: { acl: [CLIENT_ADMIN] }
      },
      {
        path: "employees",
        name: "Employees",
        component: EmployeesView,
        meta: {
          acl: [SUPER_ADMIN, ADMIN],
          title: t("EMPLOYEES.TITLE"),
          options: ["workflow_templates"]
        }
      },
      {
        path: "workflow",
        name: "WorkflowBuilderTemplates",
        component: RouterView,
        meta: { title: t("COMMON.WORKFLOW"), options: ["workflow_templates"] },
        children: [
          {
            path: "",
            name: "WorkflowBuilderTemplatesList",
            component: WorkflowView,
            meta: {
              acl: [...LENDFLOW_ROLE_GROUP, ...CLIENT_ROLE_GROUP],
              options: ["workflow_templates"]
            }
          },
          {
            path: "new",
            name: ROUTE_WORKFLOW_TEMPLATE_NEW,
            component: WorkflowTemplateNew,
            meta: {
              acl: [
                SUPER_ADMIN,
                ADMIN,
                CLIENT_ADMIN,
                UNDERWRITER,
                CLIENT_UNDERWRITER
              ],
              options: ["workflow_templates"]
            }
          }
        ]
      },
      {
        path: "email-templates",
        name: "EmailTemplatesView",
        component: RouterView,
        meta: {
          title: t("ORGANIZATION.COMMUNICATION_TEMPLATES.EMAIL.TITLE", {
            count: 2
          })
        },
        children: [
          {
            path: "",
            name: ROUTE_EMAIL_TEMPLATES,
            component: EmailTemplates,
            meta: { acl: [...LENDFLOW_ROLE_GROUP, ...CLIENT_ROLE_GROUP] }
          },
          {
            path: "new",
            name: ROUTE_EMAIL_TEMPLATES_NEW,
            component: EmailTemplatesEdit,
            meta: { acl: [...LENDFLOW_ROLE_GROUP, ...CLIENT_ROLE_GROUP] }
          },
          {
            path: ":templateId",
            name: ROUTE_EMAIL_TEMPLATES_EDIT,
            component: EmailTemplatesEdit,
            meta: { acl: [...LENDFLOW_ROLE_GROUP, ...CLIENT_ROLE_GROUP] }
          }
        ]
      },
      {
        path: "sms-templates",
        name: ROUTE_SMS_TEMPLATES_VIEW,
        component: RouterView,
        meta: {
          title: t("ORGANIZATION.COMMUNICATION_TEMPLATES.SMS.TITLE", {
            count: 2
          })
        },
        children: [
          {
            path: "",
            name: ROUTE_SMS_TEMPLATES,
            component: SmsTemplates,
            meta: { acl: [...LENDFLOW_ROLE_GROUP, ...CLIENT_ROLE_GROUP] }
          },
          {
            path: "new",
            name: ROUTE_SMS_TEMPLATES_NEW,
            component: SmsTemplatesEdit,
            meta: { acl: [...LENDFLOW_ROLE_GROUP, ...CLIENT_ROLE_GROUP] },
            beforeEnter(_, __, next) {
              if (isEmpty(store.getters["options/dynamicFields"]?.fields)) {
                store.dispatch("options/getDynamicFields").then(() => {
                  next();
                });
              } else {
                next();
              }
            }
          },
          {
            path: ":templateId",
            name: ROUTE_SMS_TEMPLATES_EDIT,
            component: SmsTemplatesEdit,
            meta: { acl: [...LENDFLOW_ROLE_GROUP, ...CLIENT_ROLE_GROUP] },
            beforeEnter(_, __, next) {
              if (isEmpty(store.getters["options/dynamicFields"]?.fields)) {
                store.dispatch("options/getDynamicFields").then(() => {
                  next();
                });
              } else {
                next();
              }
            }
          }
        ]
      },
      {
        path: "stips",
        name: "Stips",
        component: StipsView,
        meta: { acl: LENDFLOW_ROLE_GROUP, title: t("STIPS.TITLE") }
      },
      {
        path: "attributes",
        name: "AttributesBase",
        component: RouterView,
        meta: {
          acl: [...LENDFLOW_ROLE_GROUP, ...CLIENT_ROLE_GROUP],
          title: t("ATTRIBUTE_BUILDER.ATTRIBUTE_BUILDER")
        },
        children: [
          {
            path: "",
            name: "AttributesView",
            component: AttributesView
          },
          {
            path: ":id/builder",
            name: ROUTE_CUSTOM_ATTRIBUTES_BUILDER_VIEW,
            component: CustomAttributeBuilderView,
            props: true
          }
        ]
      },
      {
        path: "orchestration",
        name: ROUTE_ORCHESTRATION_LIST,
        component: OrchestrationView,
        meta: {
          acl: orchestrationAcl,
          title: t("ORCHESTRATION.ORCHESTRATION")
        }
      },
      {
        path: "contracts",
        name: ROUTE_CONTRACTS,
        meta: { acl: LENDFLOW_ROLE_GROUP, title: t("CONTRACTS.CONTRACT", 2) },
        children: [
          {
            path: "",
            name: ROUTE_CONTRACT_BUILDER,
            component: ContractBuilderView,
            beforeEnter(to, from, next) {
              const { canUserRedirectToContractBuilder } = routeAccessCheck();
              if (canUserRedirectToContractBuilder) {
                next();
              } else {
                next({ name: ROUTE_PAGE_NOT_FOUND });
              }
            },
            meta: {
              acl: [...LENDFLOW_ROLE_GROUP, CLIENT_ADMIN],
              title: t("CONTRACTS.CONTRACT_BUILDER.CONTRACT_BUILDER")
            }
          },
          {
            path: "new",
            name: ROUTE_CONTRACT_BUILDER_NEW,
            component: ContractBuilderForm,
            beforeEnter(to, from, next) {
              const { canUserRedirectToContractBuilder } = routeAccessCheck();
              if (canUserRedirectToContractBuilder) {
                next();
              } else {
                next({ name: ROUTE_PAGE_NOT_FOUND });
              }
            },
            meta: {
              acl: [...LENDFLOW_ROLE_GROUP, CLIENT_ADMIN],
              title: t("CONTRACTS.CONTRACT_BUILDER.CONTRACT_BUILDER")
            }
          },
          {
            path: ":id",
            name: ROUTE_CONTRACT_BUILDER_EDIT,
            component: ContractBuilderForm,
            beforeEnter(to, from, next) {
              const { canUserRedirectToContractBuilder } = routeAccessCheck();
              if (canUserRedirectToContractBuilder) {
                next();
              } else {
                next({ name: ROUTE_PAGE_NOT_FOUND });
              }
            },
            meta: {
              acl: [...LENDFLOW_ROLE_GROUP, CLIENT_ADMIN],
              title: t("CONTRACTS.CONTRACT_BUILDER.CONTRACT_BUILDER")
            }
          }
        ]
      },
      {
        path: "orchestration/:id",
        name: ROUTE_ORCHESTRATION_TEMPLATE,
        component: OrchestrationTemplateView,
        meta: { acl: orchestrationAcl }
      },

      {
        path: "countries",
        name: "Countries",
        component: CountriesView,
        meta: { acl: [CLIENT_ADMIN], title: t("COMMON.COUNTRIES") }
      },
      {
        path: "scorecards",
        name: "ScorecardsBase",
        component: RouterView,
        meta: { acl: [...LENDFLOW_ROLE_GROUP, ...CLIENT_ROLE_GROUP] },
        children: [
          {
            path: "",
            name: "Scorecards",
            component: ScorecardsBaseView,
            meta: { title: t("SCORECARDS.TITLE") }
          },

          {
            path: ":id",
            name: "ScorecardsTemplate",
            component: ScorecardsTemplateView
          },
          {
            path: ":id/builder",
            name: "ScorecardsBuilder",
            component: ScorecardsBuilderView,
            meta: {
              title: `${t("SCORECARDS.TITLE")} ${t("COMMON.BUILDER")}`,
              options: ["offer_templates"]
            }
          },
          {
            path: ":id/overview",
            name: "ScorecardsOverview",
            component: ScorecardsOverview,
            meta: { title: `${t("COMMON.VIEW")} ${t("COMMON.SCORECARD")}` }
          },
          {
            path: ":id/report",
            name: "ScorecardsReport",
            component: ScorecardsReportView,
            meta: { title: t("SCORECARDS.SCORECARD_REPORT") }
          },
          {
            path: "backtest-report",
            name: "ScorecardsBacktestReportList",
            meta: { title: t("SCORECARDS.SC_BACKTEST_REPORTS") },
            children: [
              {
                path: "new",
                name: "ScorecardsBacktestReportNew",
                component: ScorecardsBacktestReportBuilder,
                meta: { title: t("SCORECARDS.SC_BACKTEST_REPORTS") }
              },
              {
                path: ":id",
                name: "ScorecardsBacktestReportView",
                component: ScorecardsBacktestReportBuilder,
                meta: { title: t("SCORECARDS.SC_BACKTEST_REPORTS") },
                children: [
                  {
                    path: "edit",
                    name: "ScorecardsBacktestReportEdit",
                    component: ScorecardsBacktestReportBuilder,
                    meta: { title: t("SCORECARDS.SC_BACKTEST_REPORTS") }
                  }
                ]
              }
            ]
          },
          {
            path: "groups",
            name: "ScorecardsGroups",
            component: RouterView,
            meta: { title: t("SCORECARDS.SC_GROUPS") },
            children: [
              {
                path: "new",
                name: ROUTE_SCORECARDS_GROUP_NEW,
                component: ScorecardsGroupView
              },
              {
                path: ":id",
                name: "ScorecardsGroupEdit",
                component: ScorecardsGroupView
              },
              {
                path: ":id/report",
                name: "ScorecardsGroupReport",
                component: ScorecardsGroupReportView
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: "/funders",
    name: "Funders",
    meta: { acl: LENDFLOW_ROLE_GROUP, title: t("COMMON.FUNDER", 2) },
    component: FundersView
  },
  {
    path: "/funders/:id",
    name: "FunderInfo",
    meta: { acl: LENDFLOW_ROLE_GROUP },
    component: FunderInfoView
  },
  {
    path: "/integrations",
    name: ROUTE_INTEGRATIONS,
    redirect: { name: ROUTE_INTEGRATIONS_WIDGET },
    meta: { acl: CLIENT_ROLE_GROUP, title: t("INTEGRATIONS.TITLE") },
    component: IntegrationsView,
    children: [
      {
        path: "widget",
        name: ROUTE_INTEGRATIONS_WIDGET,
        component: WidgetIntegrationView,
        meta: {
          options: ["workflow_templates"]
        }
      },
      {
        path: "borrower-platform",
        name: ROUTE_INTEGRATIONS_BORROWER_PLATFORM,
        component: BorrowerPlatformIntegrationView,
        beforeEnter(to, from, next) {
          const { canClientRedirectToBorrowerPlatform } = routeAccessCheck();
          if (canClientRedirectToBorrowerPlatform) {
            next();
          } else {
            next({ name: ROUTE_PAGE_NOT_FOUND });
          }
        }
      },
      {
        path: "lending-service-providers",
        name: ROUTE_INTEGRATIONS_SERVICE_PROVIDER,
        component: LendingServiceProvidersView
      },
      {
        path: "data-providers",
        name: ROUTE_INTEGRATIONS_DATA_PROVIDER,
        component: DataProvidersView
      },
      {
        path: "origination",
        name: ROUTE_INTEGRATIONS_ORIGINATION,
        component: OriginationIntegrationsView
      },
      {
        path: "lenders",
        name: ROUTE_INTEGRATIONS_FUNDER,
        component: LenderIntegrationsView
      }
    ]
  },
  {
    path: "/reports",
    name: "Reports",
    component: ReportsView,
    meta: {
      acl: [ADMIN, SUPER_ADMIN, CLIENT_ADMIN, USER],
      title: t("SIDEBAR.REPORTS")
    },
    redirect() {
      const { isClientAdmin } = routeAccessCheck();

      if (isClientAdmin) {
        return {
          name: "CreditDataUsage"
        };
      }
      return {
        name: "AnalyticsServiceReport"
      };
    },
    children: [
      {
        path: "analytics-service-report",
        name: "AnalyticsServiceReport",
        component: AnalyticsServiceReport,
        meta: { acl: [ADMIN, SUPER_ADMIN] }
      },
      {
        path: "api-calls",
        name: "ApiCalls",
        component: ApiCalls,
        meta: { acl: [ADMIN, SUPER_ADMIN] }
      },
      {
        path: "credit-data-usage",
        name: "CreditDataUsage",
        component: EmbeddedClientReport,
        props: {
          reportId: IClientEmbedReportOption.BusinessCreditReport
        },
        meta: { acl: [CLIENT_ADMIN] }
      },
      {
        path: "integrations",
        name: "ClientIntegration",
        component: EmbeddedClientReport,
        props: {
          reportId: IClientEmbedReportOption.IntegrationReport
        },
        meta: { acl: [CLIENT_ADMIN] }
      },
      {
        path: "lendflow-northstar-metrics",
        name: "LendflowNorthstarMetrics",
        component: LendflowNorthstarMetrics,
        meta: { acl: [ADMIN, SUPER_ADMIN] }
      },
      {
        path: "custom-report",
        name: "CustomReport",
        component: CustomReport
      },
      {
        path: "erc/:workflowTemplateId?",
        name: ROUTE_ERC_REPORTS,
        component: ErcReports,
        props: true,
        meta: {
          acl: [ADMIN, SUPER_ADMIN, CLIENT_ADMIN, USER]
        },
        beforeEnter(to, from, next) {
          // TODO: We might want to come up with a better way to handle checking client
          const { isClientAccelerateTax } = routeAccessCheck();
          if (isClientAccelerateTax) {
            return next();
          }
          return next({ name: ROUTE_PAGE_NOT_FOUND });
        }
      },
      {
        path: "marketing-northstar-metrics",
        name: "MarketingNorthstarMetrics",
        component: MarketingNorthstarMetrics,
        meta: { acl: [ADMIN, SUPER_ADMIN] }
      },
      {
        path: "partner-sales-northstar-metrics",
        name: "PartnerSalesNorthstarMetrics",
        component: PartnerSalesNorthstarMetrics,
        meta: { acl: [ADMIN, SUPER_ADMIN] }
      },
      {
        path: "funding-sales-northstar-metrics",
        name: "FundingSalesNorthstarMetrics",
        component: FundingSalesNorthstarMetrics,
        meta: { acl: [ADMIN, SUPER_ADMIN] }
      },
      {
        path: "underwriting-lender-relations-northstar-metrics",
        name: "UnderwritingLenderRelationsNorthstarMetrics",
        component: UnderwritingLenderRelationsNorthstarMetrics,
        meta: { acl: [ADMIN, SUPER_ADMIN] }
      },
      {
        path: "customer-success-northstar-metrics",
        name: "CustomerSuccessNorthstarMetrics",
        component: CustomerSuccessNorthstarMetrics,
        meta: { acl: [ADMIN, SUPER_ADMIN] }
      },
      {
        path: "fuse-northstar-metrics",
        name: "FuseNorthstarMetrics",
        component: FuseNorthstarMetrics,
        meta: { acl: [ADMIN, SUPER_ADMIN] }
      },
      {
        path: "flow-northstar-metrics",
        name: "FlowNorthstarMetrics",
        component: FlowNorthstarMetrics,
        meta: { acl: [ADMIN, SUPER_ADMIN] }
      },
      {
        path: "embed-northstar-metrics",
        name: "EmbedNorthstarMetrics",
        component: EmbedNorthstarMetrics,
        meta: { acl: [ADMIN, SUPER_ADMIN] }
      }
    ]
  },
  {
    path: "/customize",
    name: "Customize",
    redirect: () => {
      const { isSuperAdmin } = routeAccessCheck();
      return {
        name: isSuperAdmin
          ? ROUTE_WIDGET_BRANDING_CONSENT_TEMPLATE_LIST
          : ROUTE_WIDGET_BRANDING
      };
    },
    meta: { acl: [CLIENT_ADMIN, SUPER_ADMIN] },
    component: CustomizeView,
    children: [
      {
        path: "branding",
        component: RouterView,
        meta: { acl: [CLIENT_ADMIN], title: t("CUSTOMIZE.TITLE") },
        children: [
          {
            path: "",
            name: ROUTE_WIDGET_BRANDING,
            component: WidgetBranding
          },
          {
            path: "template/:id",
            name: ROUTE_WIDGET_BRANDING_TEMPLATE,
            component: WidgetBrandingTemplate
          },
          {
            path: "template/create",
            name: ROUTE_WIDGET_BRANDING_TEMPLATE_CREATE,
            component: WidgetBrandingTemplate
          }
        ]
      },
      {
        path: "email-branding",
        name: "EmailBranding",
        redirect: { name: "EmailBranding-Select" },
        component: EmailBrandingView,
        meta: { acl: [CLIENT_ADMIN], title: t("CUSTOMIZE.EMAIL.TITLE") },
        children: [
          {
            path: "template",
            name: "EmailBranding-Select",
            component: EmailBranding
          },
          {
            path: "customize-template/:id",
            name: "EmailCustomization",
            component: CustomizeEmailTemplateView
          }
        ]
      },
      {
        path: "consent-templates",
        component: RouterView,
        meta: { title: t("CUSTOMIZE.CONSENT.TITLE") },
        children: [
          {
            path: "",
            name: ROUTE_WIDGET_BRANDING_CONSENT_TEMPLATE_LIST,
            component: ConsentTemplateList
          },
          {
            path: ":id",
            name: ROUTE_WIDGET_BRANDING_CONSENT_TEMPLATE_EDIT,
            component: ConsentTemplateEditView,
            props: true
          }
        ]
      }
    ]
  },
  {
    path: "/workflow-builder/:id",
    name: ROUTE_WORKFLOW_BUILDER,
    component: WorkflowBuilder,
    meta: { options: ["workflow_templates", "offer_templates"] },
    children: [
      {
        path: "",
        name: ROUTE_WORKFLOW_BUILDER_CONTENT,
        component: WorkflowBuilderContent,
        meta: { title: t("COMMON.WORKFLOW_BUILDER") }
      },
      {
        path: "settings",
        name: ROUTE_WORKFLOW_BUILDER_SETTINGS_VIEW,
        component: WorkflowBuilderSettingsView,
        children: [
          {
            path: "workflow",
            name: ROUTE_WORKFLOW_BUILDER_SETTINGS,
            component: WorkflowBuilderSettings
          },
          {
            path: "widget-branding",
            name: ROUTE_WORKFLOW_BUILDER_WIDGET_BRANDING,
            component: WorkflowBuilderWidgetAndBranding
          },
          {
            path: "workflow-automation",
            name: ROUTE_WORKFLOW_BUILDER_WORKFLOW_AUTOMATION,
            component: WorkflowBuilderSettingsAutomation
          },
          {
            path: "notifications/webhooks",
            name: ROUTE_WORKFLOW_BUILDER_NOTIFICATIONS_WEBHOOKS,
            component: WebhookNotifications
          },
          {
            path: "notifications/email",
            name: ROUTE_WORKFLOW_BUILDER_NOTIFICATIONS_EMAIL,
            component: EmailNotifications
          },
          {
            path: "notifications/sms",
            name: ROUTE_WORKFLOW_BUILDER_NOTIFICATIONS_SMS,
            component: SmsNotifications
          },
          {
            path: "submission",
            name: ROUTE_WORKFLOW_BUILDER_SUBMISSION,
            component: WorkflowBuilderEmailSubmission
          },
          {
            path: "document-syncing",
            name: ROUTE_WORKFLOW_BUILDER_DOC_SYNC,
            component: WorkflowBuilderDocumentSyncing
          },
          {
            path: "activity-hub",
            name: ROUTE_WORKFLOW_BUILDER_ACTIVITY_HUB,
            component: WorkflowBuilderActivityHub
          }
        ]
      },
      {
        path: "workflow-orchestration",
        name: ROUTE_WORKFLOW_ORCHESTRATION,
        component: OrchestrationTemplateView,
        meta: { title: t("COMMON.WORKFLOW_ORCHESTRATION") },
        props: { type: OrchestrationType.WORKFLOW_ORCHESTRATION },
        beforeEnter: mockedFeatureRouteMiddleware
      },
      {
        path: "borrower-platform-builder",
        name: ROUTE_BORROWER_PLATFORM_BUILDER,
        component: BorrowerPlatformBuilder,
        meta: {
          title: t("BORROWER_PLATFORM.TITLE"),
          options: ["dynamic_fields"]
        }
      }
    ]
  },
  {
    path: "/closing-docs/:offerId",
    name: "ClosingDocs",
    meta: { title: t("EXTERNAL.CLOSING_DOCS.CLOSING_DOCS") },
    component: ClosingDocsView
  },
  {
    path: "/individuals",
    name: "Individuals",
    component: IndividualsView,
    meta: { title: t("INDIVIDUAL_PROFILES.MAIN_ROUTE_NAME") }
  },
  {
    path: "/individuals/:id",
    name: ROUTE_INDIVIDUAL_PROFILE,
    component: IndividualProfile,
    children: [
      {
        path: "personal-info",
        name: ROUTE_INDIVIDUAL_PROFILE_PERSONAL_INFO,
        component: IndividualProfilePersonalInfo
      },
      {
        path: "deals",
        name: ROUTE_INDIVIDUAL_PROFILE_DEALS,
        component: BusinessDeals,
        props: { profileType: Entity.individual }
      },
      {
        path: "tracking",
        name: ROUTE_INDIVIDUAL_PROFILE_TRACKING,
        component: BusinessTrackingTab,
        props: { profileType: Entity.individual }
      },
      {
        path: "relations",
        name: ROUTE_INDIVIDUAL_RELATIONS,
        component: EntityRelationsView
      }
    ]
  },
  {
    path: "/activity-hub",
    name: ROUTE_ACTIVITY_HUB,
    component: ActivityHubView,
    meta: { title: t("ACTIVITY.ACTIVITY_HUB"), page: "ActivityHubPage" }
  },
  {
    path: "/:pathMatch(.*)*",
    name: ROUTE_PAGE_NOT_FOUND,
    component: PageNotFound,
    meta: { title: t("COMMON.NOT_FOUND") }
  }
];
